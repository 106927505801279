
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

/** Component for various order modals/menus **/
@Options({
	name: 'RegisterWindow',
	components: {},
})
export default class RegisterWindow extends Vue {
	@Prop({ default: null }) windowId!: string | null;
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "window-title" }
const _hoisted_2 = {
  id: "customFieldPolicy",
  class: "flex-col flex-center"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_register_modal = _resolveComponent("register-modal")!

  return (_openBlock(), _createBlock(_component_register_modal, {
    onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeRequest'))),
    width: 400
  }, {
    modalHeader: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.requestData.name), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", {
          innerHTML: _ctx.requestData.policy
        }, null, 8, _hoisted_3),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requestData.options, (option, i) => {
          return (_openBlock(), _createElementBlock("button", {
            key: i,
            class: _normalizeClass(option.value ? 'action-btn-naked' : 'destructive'),
            onClick: ($event: any) => (_ctx.onAgree(option.value))
          }, _toDisplayString(option.label), 11, _hoisted_4))
        }), 128))
      ])
    ]),
    _: 1
  }))
}
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_register_window = _resolveComponent("register-window")!
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal-overlay", { 'modal-loading': _ctx.isLoading }])
  }, [
    _createVNode(_component_OnClickOutside, { onTrigger: _ctx.closeModal }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["order-modal", { default: !_ctx.definedWidth }]),
          style: _normalizeStyle(_ctx.definedWidth ?? '')
        }, [
          _createVNode(_component_register_window, { windowId: _ctx.modalId }, _createSlots({
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 2
          }, [
            (_ctx.$slots.modalHeader)
              ? {
                  name: "header",
                  fn: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "modalHeader")
                  ]),
                  key: "0"
                }
              : undefined,
            (_ctx.$slots.modalFooter)
              ? {
                  name: "footer",
                  fn: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "modalFooter")
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1032, ["windowId"])
        ], 6)
      ]),
      _: 3
    }, 8, ["onTrigger"])
  ], 2))
}